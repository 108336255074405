import { createGate } from "effector-react";
import {
  attach,
  createEffect,
  createEvent,
  forward,
  restore,
} from "effector-root";
import { createAxiosInstance } from "../../../../../store/utils";
import { PledgeLevelList } from "./types";
import { useEffect, useState } from "react";

export const Gate = createGate<{ campaignId: string }>();
export const searchGate = createGate<{ searchText: string }>();

export const fetchAllPledgeLevelFx = createEffect<string, PledgeLevelList>(
  (campaignId) =>
    createAxiosInstance()
      .get(`pledge-level/campaign/${campaignId}`)
      .then((res) => {
        return res.data;
      })
);

export const fetchPledgeByCampainSimplifiedFx = createEffect<
  string,
  PledgeLevelList
>((campaignId) =>
  createAxiosInstance()
    .get(`pledge-level/campaignSimplified/${campaignId}`)
    .then((res) => {
      return res.data;
    })
);

export const useFetchPledgeLevelCountFx = (campaingId: string) => {
  const [pledgeCounter, setPledgeCounter] = useState(0);
  useEffect(() => {
    async function fetchData() {
      createAxiosInstance()
        .get(`pledge-level/countPledgeLevelsByCampaign/${campaingId}`)
        .then((res) => {
          setPledgeCounter(res.data);
        });
    }
    fetchData();
  }, [campaingId]);
  return pledgeCounter;
};
export const useFetchPledgeByCampaignFx = (campaignId: string) => {
  const [pledgeData, setPledgeData] = useState([] as any);
  useEffect(() => {
    async function fetchData() {
      createAxiosInstance()
        .get(`pledge-level/campaign/${campaignId}`)
        .then((res) => {
          setPledgeData(res.data);
        });
    }
    fetchData();
  }, [campaignId]);
  return { pledgeData };
};

export const useFetchCountBackerByPledgeIdFx = (pledgeId: string) => {
  const [pledgeCount, setPledgeCounter] = useState(0);
  useEffect(() => {
    async function fetchData() {
      createAxiosInstance()
        .get(`backer/pledgeCount/${pledgeId}`)
        .then((res) => {
          setPledgeCounter(res.data);
        });
    }
    fetchData();
  }, [pledgeId]);
  return { pledgeCount };
};
export const clearPledges = createEvent();
export const $pledgeLevels = restore(fetchAllPledgeLevelFx.doneData, []).reset(
  clearPledges
);

export const $pledgeLevelsSimplified = restore(
  fetchPledgeByCampainSimplifiedFx.doneData,
  []
).reset(clearPledges);

export const $campaignId = Gate.state.map((props) => props.campaignId);

forward({
  from: Gate.open,
  to: attach({
    source: $campaignId,
    effect: fetchAllPledgeLevelFx,
  }),
});
