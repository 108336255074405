import { Redirect, Route } from 'react-router-dom';
import { Switch } from 'react-router';
import { Routes } from '../utilities/enums';
import * as Loadables from '../loadables';
import React from 'react';
import SideBarLayout from 'components/sidebar-layout';

function AppNavigation() {
  return (
    <SideBarLayout>
      <Switch>
        <Route
          path={Routes.DASHBOARD}
          exact={true}
          component={Loadables.Dashboard}
        />
        <Route
          path={Routes.SETTINGS}
          exact={true}
          component={Loadables.Settings}
        />
        <Route
          path={Routes.BACKERS}
          exact={true}
          component={Loadables.Backers}
        />
        <Route
          path={Routes.BACKERS_PROFILE}
          exact={true}
          component={Loadables.BackerProfile}
        />
        <Route
          path={Routes.BACKERS_ACTIONS}
          exact={true}
          component={Loadables.BackerActions}
        />
        <Route
          path={Routes.BACKERS_SEGMENTS}
          exact={true}
          component={Loadables.BackerSegments}
        />
        <Route
          path={Routes.BACKERS_SEGMENTS_NEW}
          exact={true}
          component={Loadables.BackerSegmentsNew}
        />
        <Route
          path={Routes.BACKERS_SEGMENTS_DETAIL}
          exact={true}
          component={Loadables.BackerSegmentsDetail}
        />
        <Route
          path={Routes.BACKERS_SEGMENT_UPDATE}
          exact={true}
          component={Loadables.BackerSegmentUpdate}
        />
        <Route
          path={Routes.PLEDGES}
          exact={true}
          component={Loadables.Pledges}
        />
        <Route
          path={Routes.PLEDGES_PROFILE}
          exact={true}
          component={Loadables.PledgesProfile}
        />{' '}
        <Route
          path={Routes.PLEDGES_ITEMS_NEW}
          exact={true}
          component={Loadables.ItemNew}
        />
        <Route
          path={Routes.PLEDGES_SKU}
          exact={true}
          component={Loadables.Skus}
        />
        <Route
          path={Routes.SHIPPING}
          exact={true}
          component={Loadables.Shipping}
        />
        <Route
          path={Routes.PLEDGES_ITEMS}
          exact={true}
          component={Loadables.Items}
        />
        <Route
          path={Routes.PLEDGE_ITEM_DETAIL}
          exact={true}
          component={Loadables.ItemDetail}
        />
        <Route
          path={Routes.CAMPAIGN_DATA}
          exact={true}
          component={Loadables.CampaignData}
        />
        <Route
          path={Routes.PLEDGES_QUESTIONS}
          exact={true}
          component={Loadables.Questions}
        />
        <Route
          path={Routes.PLEDGES_QUESTION_DETAIL}
          exact={true}
          component={Loadables.QuestionDetail}
        />
        <Route
          path={Routes.PLEDGES_ADDONS}
          exact={true}
          component={Loadables.Addons}
        />
        <Route
          path={Routes.PLEDGES_ADDONS_DETAIL}
          exact={true}
          component={Loadables.AddonsDetail}
        />
        <Route path={Routes.SURVEY} exact={true} component={Loadables.Survey} />
        <Route
          path={Routes.SURVEY_PROGRESS}
          exact={true}
          component={Loadables.SurveyProgress}
        />
        <Route
          path={Routes.SURVEY_PROGRESS_SEND_REMIND}
          exact={true}
          component={Loadables.SurveySendRemind}
        />
        <Route
          path={Routes.SURVEY_PROGRESS_LOCK_ORDERS}
          exact={true}
          component={Loadables.SurveyLockOrders}
        />
        <Route
          path={Routes.SURVEY_PROGRESS_CHARGE_CARDS}
          exact={true}
          component={Loadables.SurveyChargeCards}
        />
        <Route
          path={Routes.SURVEY_PROGRESS_lOCK_ADDRESS}
          exact={true}
          component={Loadables.SurveyLockAddresses}
        />
        <Route
          path={Routes.SURVEY_PROGRESS_SHIPPING}
          exact={true}
          component={Loadables.SurveyShipping}
        />
        <Route
          path={Routes.SURVEY_PROGRESS_TRACK_ITEMS}
          exact={true}
          component={Loadables.SurveyTrackItems}
        />
        <Route
          path={Routes.SURVEY_USER}
          exact={true}
          component={Loadables.SurveyUser}
        />
        <Redirect exact to={Routes.DASHBOARD} />
      </Switch>
    </SideBarLayout>
  );
}

export default React.memo(AppNavigation);
