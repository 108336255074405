export enum Routes {
  BASE = '/',
  LOGIN = '/',
  PASSWORD_RESET = '/reset-password/:token',
  PROJECTS = '/projects',
  NEW_PROJECT = '/projects/new/:platform',
  // MAIN
  DASHBOARD = '/dashboard',
  BACKERS = '/backers/list',
  PLEDGES = '/settings/pledges',
  SURVEY = '/survey',
  SURVEY_PROGRESS = '/survey/progress',
  SURVEY_PROGRESS_SEND_REMIND = '/survey/progress/send-remind',
  SURVEY_PROGRESS_LOCK_ORDERS = '/survey/progress/lock-orders',
  SURVEY_PROGRESS_CHARGE_CARDS = '/survey/progress/charge-cards',
  SURVEY_PROGRESS_lOCK_ADDRESS = '/survey/progress/lock-addresses',
  SURVEY_PROGRESS_SHIPPING = '/survey/progress/shipping',
  SURVEY_PROGRESS_TRACK_ITEMS = '/survey/progress/track-items',
  // PROFILES
  BACKERS_PROFILE = '/backers/list/profile/:id',
  PLEDGES_PROFILE = '/settings/pledges/profile/:id',
  // SUB-MAIN
  PLEDGES_QUESTIONS = '/settings/questions',
  PLEDGES_ADDONS = '/settings/addons',
  PLEDGES_ITEMS = '/settings/items',
  PLEDGES_ITEMS_NEW = '/settings/items/new',
  PLEDGES_SKU = '/settings/skus',
  SHIPPING = '/settings/shipping',
  BACKERS_SEGMENTS = '/backers/segments',
  BACKERS_SEGMENTS_DETAIL = '/backers/segments/:id',
  BACKERS_SEGMENTS_NEW = '/backers/segments/new',
  BACKERS_ACTIONS = '/backers/actions',
  BACKERS_SEGMENT_UPDATE = '/backers/segments/update/:id',
  // SURVEY
  SURVEY_USER = '/survey/:id_survey',
  // ACCOUNTS
  ACCOUNTS = '/accounts',
  // DETAILS
  PLEDGE_ITEM_DETAIL = '/settings/items/detail/:id',
  PLEDGES_QUESTION_DETAIL = '/settings/questions/detail/:id',
  PLEDGES_ADDONS_DETAIL = '/settings/addons/detail/:id',
  //CampaignsData
  CAMPAIGN_DATA = '/settings/campaignsData',
  // SUPER-ADMIN
  SUPERADMIN = '/adminsaccounts/',
  SUPERADMIN_NEW_ADMIN = '/adminsaccounts/new',
  //SETTINGS
  SETTINGS = '/settings',
}
