import { createGate } from "effector-react";
import {
  attach,
  createEffect,
  createEvent,
  forward,
  restore,
} from "effector-root";
import { createAxiosInstance } from "store/utils";
import { BackerList } from "./types";
import { useEffect, useState } from "react";

export const Gate = createGate<{
  data: { campaignId: string; skip: number };
}>();
export const AllBackersGate = createGate<{ campaignId: string }>();
export const searchGate = createGate<{ searchText: string }>();
export const clearBackersList = createEvent();
export const fetchAllBackerFx = createEffect<string, BackerList>((campaignId) =>
  createAxiosInstance()
    .get(`backer/campaign/${campaignId}`)
    .then((res) => {
      return res.data;
    })
);

export const createBacker = createEffect(async (form: any) => {
  const res = await createAxiosInstance().post("backer/createBacker", form);
  return res.data;
});

export const createSegment = createEffect(async (data: any) => {
  const res = await createAxiosInstance().post("segment", data);
  return res.data;
});

export const updateSegmentFx = createEffect<any, any>(async (form: any) => {
  const { id, ...rest } = form;
  const res = await createAxiosInstance().put(`backer/${id}`, rest);
  return res.data;
});

export const useFetchAllBackerFxPaginated = (
  campaignId: string,
  page: Number,
  limit: Number,
  currentData: [],
  reload: number,
  reset: boolean
) => {
  const [data, setData] = useState([] as any);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function fetchData() {
      createAxiosInstance()
        .get(
          `backer/backersPaginated/${campaignId}?page=${page}&limit=${limit}`
        )
        .then((res) => {
          if (reset) {
            setData(res.data.backersList);
          } else {
            setData([...currentData, ...res.data.backersList]);
          }
          setHasMore(res.data.totalDoc > data.length);
          setLoading(false);
        });
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, page, reload]);
  return { data, loading, hasMore };
};

export const useFetchBackersEmails = (campaignId: string) => {
  const [data, setData] = useState([] as any);
  useEffect(() => {
    async function fetchData() {
      createAxiosInstance()
        .get(`backer/backersEmails/${campaignId}`)
        .then((res) => {
          setData(res.data);
        });
    }
    fetchData();
  }, [campaignId]);
  return data;
};

export const useFetchAllBackersFxPaginatedForSegment = (
  campaignId: string,
  page: Number,
  limit: Number,
  currentData: [],
  filters: {
    email?: string[];
    status?: string[];
    notStatus?: string[];
    order?: string[];
    notOrder?: string[];
    pledgeLevelId?: string[];
    notInPledgeLevelId?: string[];
    _id?: string[];
  }
) => {
  const [data, setData] = useState([] as any);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(true);
  const [totalDoc, setTotalDoc] = useState(0);
  useEffect(() => {
    setData([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    currentData = [];
  }, [campaignId, filters]);
  useEffect(() => {
    async function fetchData() {
      createAxiosInstance()
        .get(
          `backer/backersPaginatedSegment/${campaignId}?page=${page}&limit=${limit}&filters=${JSON.stringify(
            filters
          )}`
        )
        .then((res) => {
          setData(res.data.backersList);
          setHasMore(res.data.totalDoc > data.length);
          setTotalDoc(res.data.totalDoc);
          setLoading(false);
        });
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, page, filters, limit]);
  return { data, loading, hasMore, totalDoc };
}; // eslint-disable-line react-hooks/exhaustive-deps

export const fetchAllBackerPerBatchFx = createEffect<
  { campaignId: string; skip: number },
  BackerList
>((data) =>
  createAxiosInstance()
    .get(`backer/campaign/${data.campaignId}/batch/${data.skip}`)
    .then((res) => {
      return res.data;
    })
);

export const fetchAllBackerByPledgeLevelFx = createEffect<string, BackerList>(
  (pledgeId) =>
    createAxiosInstance()
      .get(`backer/pledgelevel/${pledgeId}`)
      .then((res) => {
        return res.data;
      })
);

export const $backers = restore(fetchAllBackerFx.doneData, []).reset(
  clearBackersList
);
export const $campaignData = AllBackersGate.state.map(
  (props) => props.campaignId
);

forward({
  from: AllBackersGate.open,
  to: attach({
    source: $campaignData,
    effect: fetchAllBackerFx,
  }),
});
