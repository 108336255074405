import i18n from "i18next";
import detector from "i18next-browser-languagedetector";

// @ts-ignore
import en from './locales/en.json';

// the translations
const resources = {
  en: {
    translation: en
  }
};
// @ts-ignore
i18n
  .use(detector)
  // .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;