import { Redirect, Route } from "react-router-dom";
import { Switch } from "react-router";
import { Routes } from "../utilities/enums";
import * as Loadables from "../loadables";
import React from "react";

function SuperAdminNavigation() {
  return (
    <Switch>
      <Route
        path={Routes.SUPERADMIN}
        exact={true}
        component={Loadables.AdminsList}
      />
      <Redirect to={Routes.SUPERADMIN} />
    </Switch>
  );
}

export default React.memo(SuperAdminNavigation);
