import {createEffect, createEvent, restore} from 'effector';
import jwt_decode from "jwt-decode";
import {api} from "../../../../store/utils";
import {IToken} from './types';


export const fetchUserData = createEffect<any, IToken, IToken>((auth0_id: string) =>
  api
    .get("user/user/" + auth0_id)
    .then((res) => res.data)
    .catch(err => console.log(err))
)

export const loginEv = createEvent<any>()
export const tokenEv = createEvent<any>()

export const $userData = restore<IToken>(loginEv, null)
  .on(fetchUserData.doneData, (state, newState) => ({ ...state, ...newState }))

export const $access_token = restore<string>(tokenEv, null)

export const handleLogin = (token: string) => {
  const decodedToken = jwt_decode<any>(token)
  if (decodedToken[`${process.env.REACT_APP_AUTH0_AUDIENCE}/api/roles`][0] === "SUPER-ADMIN") {
    const s = {
      ...decodedToken,
      role: decodedToken[`${process.env.REACT_APP_AUTH0_AUDIENCE}/api/roles`][0]
    }
    localStorage.setItem("userData", JSON.stringify(s))
    loginEv(s)
  } else {
    fetchUserData(decodedToken.sub)
    fetchUserData.doneData.watch(result => {
      const s = {
        ...decodedToken,
        ...result,
        role: decodedToken[`${process.env.REACT_APP_AUTH0_AUDIENCE}/api/roles`][0]
      }
      localStorage.setItem("userData", JSON.stringify(s))
      loginEv(s)
    })
  }
}

export const handleSaveToken = (access_token: string) => {
  setAccessToken(access_token)
  tokenEv(access_token)
}

export const resetPasswordFx = createEffect<any, any>((data: any) =>
  api
    .post("user/password-recovery", data)
    .then((res) => res.data)
    .catch((err) => {
      console.log('error', err)
      return null
    })
)
export const setSession = (data: string) => {
  if (data) {
    localStorage.setItem("userData", JSON.stringify(data))
  } else {
    localStorage.removeItem("userData")
  }
}

export const setAccessToken = (access_token: string) => {
  if (access_token) {
    localStorage.setItem("access_token", access_token)
  } else {
    localStorage.removeItem("access_token")
  }
}

