import { Redirect, Route, Switch } from "react-router-dom";
import { Routes } from "../utilities/enums";
import * as Loadables from "../loadables";
import React from "react";

function AuthNavigation() {
  return (
    <Switch>
      <Route
        path={Routes.SURVEY_USER}
        exact={true}
        component={Loadables.SurveyUser}
      />
      <Route path={Routes.LOGIN} exact={true} component={Loadables.Home} />
      <Route
        path={Routes.PASSWORD_RESET}
        exact={true}
        component={Loadables.ResetPassword}
      />
      {/*<Route path={Routes.SURVEY} exact={true} component={Loadables.Survey}/>*/}
      <Redirect exact to={Routes.LOGIN} />
    </Switch>
  );
}

export default React.memo(AuthNavigation);
