import * as React from "react";
import DataUsageOutlinedIcon from "@material-ui/icons/DataUsageOutlined";

const PageLoader = (
  <div className="flex items-center justify-center h-full self-center">
    <DataUsageOutlinedIcon
      fontSize="large"
      className="text-o-blue animate-spin text-6xl"
    />
  </div>
);

export default PageLoader;
