import React from "react";

import "./i18n";
import Navigation from "./navigation/";
import { getAccessToken, getSession } from "./store/utils/session";
import { createCtx } from "./utilities/context";

const [isLogged, ContextProvider] = createCtx(false);
export const LoginContext = isLogged;

function App() {

  getAccessToken();
  getSession();
  return (
    <ContextProvider>
      <Navigation />
    </ContextProvider>
  );
}

export default App;
