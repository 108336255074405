export const filterArrayByString = (mainArr: any, searchText: string) => {
  if (searchText === '') {
    return mainArr;
  }

  searchText = searchText.toLowerCase();

  return mainArr.filter((itemObj: any) => {
    return searchInObj(itemObj, searchText);
  });
}

export const searchInObj = (itemObj: any, searchText: string) => {
  for (const prop in itemObj) {
    if (!itemObj.hasOwnProperty(prop)) {
      continue;
    }

    const value: any = itemObj[prop];

    if (typeof value === 'string') {
      if (searchInString(value, searchText)) {
        return true;
      }
    }

    else if (Array.isArray(value)) {
      if (searchInArray(value, searchText)) {
        return true;
      }
    }

    if (typeof value === 'object') {
      if (searchInObj(value, searchText)) {
        return true;
      }
    }
  }
}

export const searchInArray = (arr: any, searchText: string) => {
  for (const value of arr) {
    if (typeof value === 'string') {
      if (searchInString(value, searchText)) {
        return true;
      }
    }

    if (typeof value === 'object') {
      if (searchInObj(value, searchText)) {
        return true;
      }
    }
  }
}

export const searchInString = (value: string, searchText: string) => {
  return value.toLowerCase().includes(searchText);
}
