import React from "react";
import {
  Avatar,
  IconButton,
  InputAdornment,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
  SwipeableDrawer,
  MenuItem,
  Menu,
} from "@material-ui/core";
import clsx from "clsx";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import { useGate, useStore } from "effector-react";
import { useHistory } from "react-router-dom";
import avatar from "../../assets/Ellipse1.png";
//import logo from "../../assets/logo.png";
import {
  $backers,
  AllBackersGate as Gate,
  clearBackersList,
} from "../../containers/apps/backers/list/model";
import { clearAddons } from "../../containers/apps/pledges/addons/model";
import { clearPledges } from "../../containers/apps/pledges/list/model";
import { clearQuestions } from "../../containers/apps/pledges/questions/model";
import {
  $selectedCampaign,
  setSelectedCampaign,
} from "../../containers/apps/projects/projects-list/model";
import { $userData } from "../../containers/auth/login/model";
import routes from "../../navigation/routes.json";
import { filterArrayByString } from "../../store/utils";
import { logout } from "../../store/utils/session";
import MenuList from "../menu-list/";
import MenuIcon from "@material-ui/icons/Menu";
// import { filterArrayByString } from "../../store/utils";
import useWindowDimensions from "store/utils/useDimension";
interface Props {
  children?: React.ReactNode | React.ReactNode[];
  header?: React.ReactNode;
  list?: [];
}

const bgColorsList = [
  "gray",
  "red",
  "yellow",
  "green",
  "blue",
  "indigo",
  "purple",
  "pink",
];

const useStyles = makeStyles({
  cssLabel: {
    color: "white",
  },
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: `white !important`,
    },
    backgroundColor: "white",
    width: 300,
    height: 48,
    borderRadius: 8,
  },
  cssFocused: {},
  notchedOutline: {
    borderWidth: "0px !important",
  },
  table: {
    borderRadius: 16,
  },
});

const SideBarLayout: React.FunctionComponent<Props> = (props: Props) => {
  const id = $selectedCampaign.getState();
  useGate(Gate, { campaignId: id });
  const backers = useStore($backers);
  const classes = useStyles();
  const history = useHistory();
  const userData = useStore($userData);
  const [searchText, setSearchText] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [filteredData, setFilteredData] = React.useState([]);
  const [sideBarOpen, setSideBarOpen] = React.useState(false);
  const { width } = useWindowDimensions();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    function getFilteredArray(entities: any, searchText: string): any {
      const arr = Object.keys(entities).map((id) => entities[id]);
      if (searchText.length < 4) {
        return arr;
      }
      return filterArrayByString(arr, searchText);
    }

    if (backers) {
      const s = backers.map((x) => ({
        email: x.email,
        full_name: x.full_name,
        _id: x._id,
        image_profile_url: x.image_profile_url,
      }));
      setFilteredData(getFilteredArray(s, searchText).slice(0, 6));
    }
  }, [searchText, backers]);

  function navigate(route: string) {
    history.push(route);
  }

  function signOut() {
    logout();
  }

  function goToProjects() {
    setSelectedCampaign("");
    clearBackersList();
    clearPledges();
    clearAddons();
    clearQuestions();
    localStorage.setItem("selectedProject", "");
  }

  function goToSettings() {
    goToProjects();
    navigate("/settings");
  }

  function goToBackerProfile(id: string) {
    history.replace("/backers/list/profile/" + id);
    setSearchText("");
  }

  const toggleDrawer = (state: boolean) => {
    setSideBarOpen(state);
  };

  return (
    <div className="flex bg-background flex-row h-screen">
      {/*
          <img
          src={logo}
          className="object-contain h-12 cursor-pointer"
          alt="logo"
          onClick={goToProjects}
        />*/}

      {width > 1200 ? (
        <div className="bg-white pt-8 pl-8 w-1/5 overflow-auto h-full max-h-screen">
          <p
            onClick={goToProjects}
            className="text-o-title mx-2 self-center font-bold align-middle mb-1 cursor-pointer"
          >
            KICKSURVEY
          </p>

          <div className="h-16" />
          {routes.slice(0, 1).map((route, i) => (
            <MenuList key={i} route={route} />
          ))}
          <div className="pt-4 pb-8 pr-10">
            <div className="border-b border-b-gray" />
          </div>
          {routes.slice(1).map((route, i) => (
            <MenuList key={i} route={route} />
          ))}
        </div>
      ) : sideBarOpen ? (
        <SwipeableDrawer
          className="bg-white pt-8 pl-8 w-1/5 overflow-auto h-full max-h-screen relative"
          open={sideBarOpen}
          onOpen={() => toggleDrawer(true)}
          onClose={() => toggleDrawer(false)}
        >
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            className="focus:outline-none outline-none hover:outline-none"
            style={{
              position: "absolute",
              top: "18px",
              cursor: "pointer",
              right: "15px",
            }}
            onClick={() => toggleDrawer(false)}
          >
            <CloseIcon />
          </IconButton>
          <div id="sidebar" className="w-64 p-8">
            <p
              onClick={goToProjects}
              className="text-o-title mx-2 self-center font-bold align-middle mb-1 cursor-pointer"
            >
              KICKSURVEY
            </p>

            <div className="h-16" />
            {routes.slice(0, 1).map((route, i) => (
              <MenuList key={i} route={route} />
            ))}
            <div className="pt-4 pb-8 pr-10">
              <div className="border-b border-b-gray" />
            </div>
            {routes.slice(1).map((route, i) => (
              <MenuList key={i} route={route} />
            ))}
          </div>{" "}
        </SwipeableDrawer>
      ) : null}

      <div
        className={`${
          width > 1200 ? "w-4/5" : "w-full"
        } bg-background flex items-start flex-col h-full pt-6 px-8 max-h-screen overflow-y-auto`}
      >
        <div className="w-full flex flex-row flex-shrink-0 justify-between pb-16">
          {width < 1200 ? (
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              className="focus:outline-none outline-none hover:outline-none"
              style={{
                cursor: "pointer",
              }}
              onClick={() => toggleDrawer(true)}
            >
              <MenuIcon className="text-o-title" />
            </IconButton>
          ) : null}
          {width > 1200 && (
            <div className="flex flex-col  ">
              <TextField
                id="outlined-basic"
                placeholder="Search for backers... "
                onChange={(e) => setSearchText(e.target.value)}
                value={searchText}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={{ color: "#999" }} />
                    </InputAdornment>
                  ),
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
                variant="outlined"
              />
              {searchText.length > 4 && (
                <div
                  className="z-10 bg-white rounded-2xl absolute"
                  style={{ top: "76px", width: "300px" }}
                >
                  <TableContainer className="bg-white rounded-2xl shadow">
                    <Table aria-label="simple table">
                      <TableBody>
                        {filteredData?.map((row: any, i: number) => (
                          <TableRow
                            key={i}
                            className="cursor-pointer"
                            hover
                            role="checkbox"
                            onClick={() => goToBackerProfile(row._id)}
                          >
                            <TableCell component="th" scope="row">
                              <div className="flex flex-row items-center">
                                <div className="flex flex-row items-center">
                                  <p
                                    className={clsx(
                                      "p-3 rounded-full text-white text-lg font-mono",
                                      `bg-${
                                        bgColorsList[
                                          Math.floor(Math.random() * 8)
                                        ]
                                      }-600`
                                    )}
                                  >
                                    {row.full_name.slice(0, 2)}
                                  </p>
                                </div>
                                <div>
                                  <p className="pl-2 text-o-title text-sm font-semibold">
                                    {row.full_name}
                                  </p>
                                  <p className="pl-2 text-o-subTitle font-normal text-sm">
                                    {row.email}
                                  </p>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
            </div>
          )}
          <div className="flex flex-row items-center">
            <Avatar alt="Remy Sharp" src={userData?.picture ?? avatar} />
            <Typography className="text-base text-o-title pl-3">
              {userData?.nickname ?? "CMO Bobobark"}
            </Typography>
            <div>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                className="focus:outline-none outline-none hover:outline-none"
                onClick={handleClick}
              >
                <ExpandMoreIcon />
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={goToSettings}>Account settings</MenuItem>
                <MenuItem onClick={signOut}>Sign out</MenuItem>
              </Menu>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col">{props.children}</div>
      </div>
    </div>
  );
};

export default SideBarLayout;
