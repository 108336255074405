import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppNavigation from "./AppNavigation";
import ProjectsNavigation from "./ProjectsNavigation";
import SuperAdminNavigation from "./SuperAdminNavigation";
import AuthNavigation from "./AuthNavigation";
import { $userData } from "../containers/auth/login/model";
import { useStore } from "effector-react";
import { $selectedCampaign } from "../containers/apps/projects/projects-list/model";

function Navigation() {
  const userData = useStore($userData);
  const role = userData?.role;
  const campaignId = useStore($selectedCampaign);
  return (
    <Router>
      {/* {state ? <AppNavigation /> : <ProjectsNavigation />} */}
      {/*<AccountsNavigation/>*/}
      {role === "SUPER-ADMIN" ? (
        <SuperAdminNavigation />
      ) : role === "ADMIN" || role === "USER" ? (
        campaignId ? (
          <AppNavigation />
        ) : (
          <ProjectsNavigation />
        )
      ) : (
        <AuthNavigation />
      )}
    </Router>
  );
}

export default Navigation;
