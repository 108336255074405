import {Redirect, Route} from "react-router-dom";
import {Routes} from "../utilities/enums";
import {Switch} from "react-router";

import * as Loadables from "../loadables";
import React from "react";

function ProjectsNavigation() {
  return (
    <Switch>
      {/*<Route path={Routes.SURVEY_USER} exact={true} component={Loadables.SurveyUser}/>*/}
      <Route path={Routes.PROJECTS} exact={true} component={Loadables.Projects}/>
      <Route path={Routes.NEW_PROJECT} exact={true} component={Loadables.NewProject}/>
      <Route path={Routes.SETTINGS} exact={true} component={Loadables.Settings}/>
      <Redirect exact to={Routes.PROJECTS} />
    </Switch>
  )
}

export default React.memo(ProjectsNavigation)
