import { createGate } from "effector-react";
import {
  attach,
  createEffect,
  createEvent,
  forward,
  restore,
} from "effector-root";
import { createAxiosInstance } from "store/utils";
import { AddonList } from "./types";
import React from "react";

export const Gate = createGate<{ campaignId: string }>();

export const useFetchAddons = (campaignId: string, reload: number) => {
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    async function fetchData() {
      createAxiosInstance()
        .get(`add-on/campaign/${campaignId}`)
        .then((res) => {
          setData(res.data);
        });
    }
    fetchData();
  }, [campaignId, reload]);
  return { data };
};

export const useFetchAddonsCount = (campaignId: string, addonId: string) => {
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    async function fetchData() {
      createAxiosInstance()
        .get(`survey/addonCount/${campaignId}?addonId=${addonId}`)
        .then((res) => {
          setData(res.data);
        });
    }
    fetchData();
  }, [campaignId, addonId]);
  return data;
};

export const fetchAllAddonsFx = createEffect<string, AddonList>((campaignId) =>
  createAxiosInstance()
    .get(`add-on/campaign/${campaignId}`)
    .then((res) => res.data)
);

export const clearAddons = createEvent();
export const $addons = restore(fetchAllAddonsFx.doneData, []).reset(
  clearAddons
);
export const $campaignId = Gate.state.map((props) => props.campaignId);

forward({
  from: Gate.open,
  to: attach({
    source: $campaignId,
    effect: fetchAllAddonsFx,
  }),
});
