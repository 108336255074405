import _ from "lodash";

export function getFormData(object: any) {
  const formData = new FormData();
  Object.keys(object).forEach(key => {
    if (_.isArray(object[key])) {
      formData.append((key), JSON.stringify(object[key]));
    } else
      formData.append((key), object[key]);
  });
  return formData;
}

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});