import axios from "axios";


export const api = axios.create({
  // baseURL: 'http://localhost:8080/',
  baseURL:
    process.env.NODE_ENV !== "production"
      ? "http://localhost:8080/"
      : process.env.REACT_APP_API_ROOT,
  headers: {
    authorization: 'Bearer ' + localStorage.getItem("access_token"),
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});

export const createAxiosInstance = () =>  {
  return axios.create({
    // baseURL: 'http://localhost:8080/',
    baseURL:
      process.env.NODE_ENV !== "production"
        ? "http://localhost:8080/"
        : process.env.REACT_APP_API_ROOT,
    headers: {
      authorization: 'Bearer ' + localStorage.getItem("access_token"),
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  });
}
/*api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      router.model.history.push(router.Paths.LOGIN);
    }

    return Promise.reject(error);
  },
)*/
