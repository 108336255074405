import { AxiosError } from 'axios';
import { createGate } from 'effector-react';
import { attach, createEffect, createEvent, forward, restore } from 'effector-root';
import { createAxiosInstance } from "../../../../../store/utils";
import { QuestionList } from "./types";

export const Gate = createGate<{ campaignId: string }>();
export const fetchAllQuestionFx = createEffect<string, QuestionList>((campaignId) =>
createAxiosInstance()
    .get(`question/campaign/${campaignId}`)//
    .then((res) =>  res.data)
);

export const deleteQuestionFx = createEffect<any, string, AxiosError>(
  (qstId: string) =>
  createAxiosInstance().delete(`question/${qstId}`)
)
export const clearQuestions = createEvent()
export const $questions = restore(fetchAllQuestionFx.doneData, []).reset(clearQuestions)
export const $campaignId = Gate.state.map((props) => props.campaignId);

forward({
  from: Gate.open,
  to: attach({
    source: $campaignId,
    effect: fetchAllQuestionFx,
  }),
});


