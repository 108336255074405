import loadable, { LoadableComponent } from '@loadable/component';
import PageLoader from './components/page-loader';

const fallback = PageLoader; // add loader component

// prettier-ignore
export const Home: LoadableComponent<any> = loadable(() => import('./containers/auth/login'), {fallback});
export const ResetPassword: LoadableComponent<any> = loadable(
  () => import('./containers/auth/new-password'),
  { fallback }
);
export const Projects: LoadableComponent<any> = loadable(
  () => import('./containers/apps/projects/projects-list'),
  { fallback }
);
export const NewProject: LoadableComponent<any> = loadable(
  () => import('./containers/apps/projects/projects-new'),
  { fallback }
);
export const Settings: LoadableComponent<any> = loadable(
  () => import('./containers/apps/settings'),
  { fallback }
);
export const Dashboard: LoadableComponent<any> = loadable(
  () => import('./containers/apps/dashboard'),
  { fallback }
);
export const Backers: LoadableComponent<any> = loadable(
  () => import('./containers/apps/backers/list'),
  { fallback }
);
export const BackerProfile: LoadableComponent<any> = loadable(
  () => import('./containers/apps/backers/profile'),
  { fallback }
);
export const BackerSegments: LoadableComponent<any> = loadable(
  () => import('./containers/apps/backers/segments'),
  { fallback }
);
export const BackerSegmentsNew: LoadableComponent<any> = loadable(
  () => import('./containers/apps/backers/segments/new'),
  { fallback }
);
export const BackerSegmentsDetail: LoadableComponent<any> = loadable(
  () => import('./containers/apps/backers/segments/detaille'),
  { fallback }
);
export const BackerSegmentUpdate: LoadableComponent<any> = loadable(
  () => import('./containers/apps/backers/segments/edit'),
  { fallback }
);
export const BackerActions: LoadableComponent<any> = loadable(
  () => import('./containers/apps/backers/actions'),
  { fallback }
);
export const Pledges: LoadableComponent<any> = loadable(
  () => import('./containers/apps/pledges/list'),
  { fallback }
);
export const PledgesProfile: LoadableComponent<any> = loadable(
  () => import('./containers/apps/pledges/profile'),
  { fallback }
);
export const Items: LoadableComponent<any> = loadable(
  () => import('./containers/apps/pledges/items'),
  { fallback }
);
export const ItemDetail: LoadableComponent<any> = loadable(
  () => import('./containers/apps/pledges/item-detail'),
  { fallback }
);
export const ItemNew: LoadableComponent<any> = loadable(
  () => import('./containers/apps/pledges/item-new'),
  { fallback }
);
export const Questions: LoadableComponent<any> = loadable(
  () => import('./containers/apps/pledges/questions'),
  { fallback }
);
export const QuestionDetail: LoadableComponent<any> = loadable(
  () => import('./containers/apps/pledges/question-detail'),
  { fallback }
);
export const Addons: LoadableComponent<any> = loadable(
  () => import('./containers/apps/pledges/addons'),
  { fallback }
);
export const AddonsDetail: LoadableComponent<any> = loadable(
  () => import('./containers/apps/pledges/addons-detail'),
  { fallback }
);
export const CampaignData: LoadableComponent<any> = loadable(
  () => import('./containers/apps/pledges/campaign'),
  { fallback }
);

export const Skus: LoadableComponent<any> = loadable(
  () => import('./containers/apps/pledges/skus'),
  {
    fallback,
  }
);

export const Shipping: LoadableComponent<any> = loadable(
  () => import('./containers/apps/pledges/shipping'),
  {
    fallback,
  }
);

export const Survey: LoadableComponent<any> = loadable(
  () => import('./containers/apps/survey/list'),
  { fallback }
);
export const SurveyUser: LoadableComponent<any> = loadable(
  () => import('./containers/apps/survey/real'),
  { fallback }
);
export const SurveyProgress: LoadableComponent<any> = loadable(
  () => import('./containers/apps/survey/progress'),
  { fallback }
);
export const SurveySendRemind: LoadableComponent<any> = loadable(
  () => import('./containers/apps/survey/progress/send-remind'),
  { fallback }
);
export const SurveyLockOrders: LoadableComponent<any> = loadable(
  () => import('./containers/apps/survey/progress/lock-orders'),
  { fallback }
);
export const SurveyChargeCards: LoadableComponent<any> = loadable(
  () => import('./containers/apps/survey/progress/charge-cards'),
  { fallback }
);
export const SurveyLockAddresses: LoadableComponent<any> = loadable(
  () => import('./containers/apps/survey/progress/lock-addresses'),
  { fallback }
);
export const SurveyShipping: LoadableComponent<any> = loadable(
  () => import('./containers/apps/survey/progress/shipping'),
  { fallback }
);
export const SurveyTrackItems: LoadableComponent<any> = loadable(
  () => import('./containers/apps/survey/progress/track-items'),
  { fallback }
);
export const Accounts: LoadableComponent<any> = loadable(
  () => import('./containers/apps/accounts/super-admin'),
  { fallback }
);
export const AdminsList: LoadableComponent<any> = loadable(
  () => import('./containers/super-admin/admins-list/'),
  { fallback }
);
