import { setSelectedCampaign } from "../../containers/apps/projects/projects-list/model";
import { loginEv, tokenEv } from "../../containers/auth/login/model";
import jwtDecode from "jwt-decode";

export const setSession = (data?: string) => {
  if (data) {
    localStorage.setItem("userData", JSON.stringify(data))
  } else {
    localStorage.removeItem("userData")
  }
}

export const logout = () => {
  setSession();
  setSelectedCampaign("")
  localStorage.setItem("selectedProject", '')
  loginEv(null)
};

export const isAuthTokenValid = (access_token:string|null) => {
  if (!access_token) {
    return false;
  }
  const decoded: {exp:number} = jwtDecode(access_token); // to change to JWT type
  const currentTime = Math.floor(Date.now()/1000);
  if (decoded.exp < currentTime) {
    console.warn('access token expired');
    // console.log('access token expired');
    return false;
  } else {
    return true;
  }
};

export const getSession = () => {
  const userData = localStorage.getItem("userData")
  if (userData) {
    const s = JSON.parse(userData)
    loginEv(s)
  }
  else {
    return
  }
}

export const getAccessToken = () => {
  const access_token = localStorage.getItem("access_token")
  const tokenValidation = isAuthTokenValid(access_token)
  if (access_token && tokenValidation) {
    tokenEv(access_token)
    // axios.defaults.headers.common['Authorizationa'] = `Bearer ${access_token}`
  } else { logout() }
}
