import {
  Avatar,
  Collapse,
  createStyles,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Icon from "@material-ui/core/Icon";
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import clsx from "clsx";

interface Props {
  readonly header?: React.ReactNode;
  route: {
    name: string;
    route: string;
    icon: string;
    subList?: {
      name: string;
      route: string;
    }[];
  };
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: "#FFFFFF",
      paddingBottom: 0,
      paddingTop: 0,
    },
    collapsedList: {
      padding: 0,
    },
    openIcon: {
      color: "#fff",
      backgroundColor: "#484848",
      borderRadius: 8,
    },
    closeIcon: {
      color: "#758795",
      backgroundColor: "#EDF0F3",
      borderRadius: 8,
    },
    textOpen: {
      color: "#484848",
      fontWeight: 700,
      fontSize: 14,
      // backgroundColor: "#EDF0F3",
    },
    textClose: {
      color: "#758795",
      fontWeight: 600,
      fontSize: 14,
    },
    textListOpen: {
      color: "#484848",
      fontWeight: 600,
      fontSize: 14,
      margin: 0,
    },
    textListClose: {
      color: "#758795",
      fontWeight: 500,
      fontSize: 14,
      margin: 0,
    },
    nested: {
      fontSize: 14,
      paddingLeft: 72,
      paddingTop: 0,
      // paddingBottom: 2
    },
  })
);

const MenuList: React.FunctionComponent<Props> = (props: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const currentRoute = location.pathname;
  const { name, route, icon, subList } = props.route;
  const isCurrent = currentRoute.split("/")[1] === route.split("/")[1];
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    if (subList && subList.length > 0) {
      setOpen(true);
      handleNavigation(subList[0].route);
    } else {
      handleNavigation(route);
    }
  };
  const Expand: React.FunctionComponent<{ isOpen: boolean }> = ({ isOpen }) => {
    return isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />;
  };

  function handleNavigation(route: string) {
    history.push(route);
  }

  return (
    <List
      className={clsx(classes.root, "")}
      component="nav"
      aria-labelledby="list"
    >
      <ListItem
        onClick={handleClick}
        style={{
          paddingBottom: "0px !important",
          backgroundColor: "transparent",
          cursor: "pointer",
        }}
      >
        <ListItemAvatar>
          <Avatar className={isCurrent ? classes.openIcon : classes.closeIcon}>
            <Icon>{icon}</Icon>
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={name}
          disableTypography
          className={isCurrent ? classes.textOpen : classes.textClose}
        />
        {subList && (
          <ListItemSecondaryAction onClick={() => setOpen(!open)}>
            <IconButton
              edge="end"
              className="hover:text-o-blue"
              style={{ backgroundColor: "transparent" }}
            >
              <Expand isOpen={open} />
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>
      {subList && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div">
            {subList.map((one, i) => {
              const isSubCurrent =
                one.route.split("/")[2] === currentRoute.split("/")[2];
              return (
                <ListItem
                  key={i}
                  className={classes.nested}
                  style={{
                    backgroundColor: "transparent",
                    cursor: "pointer",
                  }}
                  onClick={() => handleNavigation(one.route)}
                >
                  <ListItemText
                    primary={one.name}
                    className={
                      (i === 0 ? isCurrent && isSubCurrent : isSubCurrent)
                        ? classes.textListOpen
                        : classes.textListClose
                    }
                    disableTypography
                  />
                </ListItem>
              );
            })}
          </List>
        </Collapse>
      )}
    </List>
  );
};

export default MenuList;
