import { createGate } from "effector-react";
import {
  attach,
  createEffect,
  createEvent,
  createStore,
  forward,
  restore,
} from "effector-root";
import { createAxiosInstance } from "store/utils";
import { CompaignList } from "./types";
import { useState, useEffect } from "react";

export const Gate = createGate<{ userId: string }>();

export const fetchAllCampaignByUser = createEffect<string, CompaignList>(
  (userId) =>
    createAxiosInstance()
      .get(`campaign/user/${userId}`)
      .then((res) => res.data)
      .catch((err) => console.log(err.response.message))
);

export const useFetchCampaignsByUser = (userId: string | undefined) => {
  const [data, setData] = useState([] as any);
  useEffect(() => {
    async function fetchData() {
      createAxiosInstance()
        .get(`campaign/user/${userId}`)
        .then((res) => {
          setData(res.data);
        });
    }
    userId && fetchData();
  }, [userId]);
  return data;
};

export const useFetchCampaignNameById = (campaignId: string) => {
  const [data, setData] = useState([] as any);
  useEffect(() => {
    async function fetchData() {
      createAxiosInstance()
        .get(`campaign/name/${campaignId}`)
        .then((res) => {
          setData(res.data);
        });
    }
    campaignId && fetchData();
  }, [campaignId]);
  return data;
};

export const deleteCampaign = createEffect<any, string>((campaignId: string) =>
  createAxiosInstance().post(`campaign/delete/${campaignId}`)
);

export const fetchAllPledges = createEffect<void, any>(() =>
  createAxiosInstance()
    // .get('user/campaigns', data)
    .get("campaign")
    .then((res) => res.data)
    .catch((err) => console.log(err.response.message))
);

export const setSelectedCampaign = createEvent<string>();
export const clearSelectedCampaign = createEvent();

export const $campaign = restore(fetchAllCampaignByUser.doneData, []);

function getSelectedProject() {
  return localStorage.getItem("selectedProject") ?? "";
}

export const $userId = Gate.state.map((props) => props.userId);

export const $selectedCampaign = createStore(getSelectedProject());
$selectedCampaign
  .on(setSelectedCampaign, (state, cp) => cp)
  .reset(clearSelectedCampaign);

forward({
  from: Gate.open,
  to: attach({
    source: $userId,
    effect: fetchAllCampaignByUser,
  }),
});

export const selectedCampaign = {};
